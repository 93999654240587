import Header from '../../components/header';
import Footer from '../../components/footer';

// import CatalogNav from '../../catalog/components/CatalogHeader';

import ScrollArrow from '../../components/scrollarrow';
import PopupDialog from '../modal/PopupDialog';

const RouteMain = (props) => (
  <>
    <PopupDialog hash="_" html="..." enable={false} />
    <Header />
    <div className='container flex flex-row items-stretch content-start justify-start flex-grow'>
      {props.children}
    </div>
    <Footer />
    <ScrollArrow />
    <div id="show-article" />
    <div id="show-modal" />
  </>
);

export default RouteMain;