import { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import useCartAction from '../hook/cartaction';
import { numberFormatCurrency } from '../util/index';
import { useTranslation } from 'react-i18next';

const PopperContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  background-color: white;
  padding:3px;
  box-sizing: border-box;
  text-align: center;

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: " ";
      position: absolute;
      top: -8px;
      
      right: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: white;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .arrow + div {
    max-height:324px;
    overflow-y: auto;
    overflow-x: none;
    padding:10px;
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: -15px;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

const PopCart = ({ refElement, onGoTo = (cartId) => null }) => {
  const { t } = useTranslation('home');
  //const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', enabled: true, options: { offset: [0, 6] } },
    ],
  });

  const [state] = useCartAction();
  const [cartList, setCartList] = useState([]);

  useEffect(() => {
    const cartFiltered = state.cart.filterAsTyp('Z');
    setCartList(cartFiltered);
    console.log('popup cartFiltered', cartFiltered);

    return () => {
      setCartList([]);
    };
  }, []);

  return (
    <>
      <PopperContainer ref={setPopperElement} style={styles.popper} { ...attributes.popper } className="">
        <div ref={setArrowElement} style={styles.arrow} className="arrow" id="cart_popup" />
        <div className="flex-row min-w-200">
          {cartList.length > 0 && cartList.map((cart, index) => (
            <div className="" key={`cartpopup_${cart.id}`}>
              {index > 0 && (
                <hr className="my-3 border border-b-0 border-gray-200" />
              )}
              <div className="flex flex-row text-xs">
                <div className="relative top-0.5 h-5">
                  <svg
                    className="inline-block mr-1 -mt-1"
                    style={{ width: '17px', height: '17px' }}
                    viewBox="0 -31 512.00033 512"
                    fill="currentColor"
                  >
                    <path d="m166 300.003906h271.003906c6.710938 0 12.597656-4.4375 14.414063-10.882812l60.003906-210.003906c1.289063-4.527344.40625-9.390626-2.433594-13.152344-2.84375-3.75-7.265625-5.964844-11.984375-5.964844h-365.632812l-10.722656-48.25c-1.523438-6.871094-7.617188-11.75-14.648438-11.75h-91c-8.289062 0-15 6.710938-15 15 0 8.292969 6.710938 15 15 15h78.960938l54.167968 243.75c-15.9375 6.929688-27.128906 22.792969-27.128906 41.253906 0 24.8125 20.1875 45 45 45h271.003906c8.292969 0 15-6.707031 15-15 0-8.289062-6.707031-15-15-15h-271.003906c-8.261719 0-15-6.722656-15-15s6.738281-15 15-15zm0 0"/><path d="m151 405.003906c0 24.816406 20.1875 45 45.003906 45 24.8125 0 45-20.183594 45-45 0-24.8125-20.1875-45-45-45-24.816406 0-45.003906 20.1875-45.003906 45zm0 0"/><path d="m362.003906 405.003906c0 24.816406 20.1875 45 45 45 24.816406 0 45-20.183594 45-45 0-24.8125-20.183594-45-45-45-24.8125 0-45 20.1875-45 45zm0 0" />
                  </svg>
                  <span className="relative inline-block font-sans font-bold text-white rounded-full bg-primary " style={{ minWidth: '17px', height: '17px', padding: '1px' }}>
                    <span className="block -m-px">
                      {state.product.countProduct(cart.id)}
                    </span>
                  </span>
                </div>
                <div className="flex-grow -mt-px text-base text-right text-gray-500">
                  {/* Razem:&nbsp; */}
                  <span className="font-semibold text-primary">
                    {numberFormatCurrency(state.product.priceCart(cart.id), state.cart.getCurrency('Z'))}
                  </span>
                </div>
              </div>
              <button
                type="button"
                className="w-full p-2 px-2 mt-1 text-xs leading-3 text-gray-500 border border-gray-500 rounded border-1 focus:outline-none hover:border-gray-800 hover:text-gray-800"
                onClick={() => onGoTo(cart.id)}
                title={`${cart.id}`}
              >
                {t('pokaż')} <b>{cart.name}</b>
              </button>
            </div>
          ))}
        </div>
      </PopperContainer>
    </>
  );
};

export default PopCart;
